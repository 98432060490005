// Your web app's Firebase configuration
export const firebaseConfig = {
    apiKey: "AIzaSyB81QMyEJa0ysF9hx6teeCQoDBu8Mk9uC8",
    authDomain: "linchpin-everest-staging.firebaseapp.com",
    databaseURL: "https://linchpin-everest-staging.firebaseio.com",
    projectId: "linchpin-everest-staging",
    storageBucket: "linchpin-everest-staging.appspot.com",
    messagingSenderId: "725338554283",
    appId: "1:725338554283:web:cab9e3d64515cf5623dcee",
    measurementId: "G-GD75XG77TP"
};
export const cloudRunDomainId = "sb5zv4pskq-ew.a.run.app";
export const firebaseCloudFunctionUrl = "https://europe-west1-linchpin-everest-staging.cloudfunctions.net";
export const hostUrl = "https://staging.linchpin.cloud";
export const firebaseCloudFunctionRegions = ["europe-west1"];
export const appleWebLoginEnabled = false;
export const ALGOLIA_ID = "V54OIE2V1S";
export const productFruitsProjectCode = "oOSl3vMwLSctOD1p"; // staging project code
export const hubspot = undefined;
export const activatePerformanceStats = true;
export const reindexingLimit: number | undefined = undefined;
