const urlPatternString = "^https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{2,256}(:\\d{1,5}|.[a-zA-Z]{2,})?\\b([-a-zA-Z0-9@:%_+.~#?&/=()]*)$";

export function urlPattern() {
    return new RegExp(urlPatternString, "g");
}

export function youtubeUrlPattern() {
    return combinedRegex([
        /^(?:https?:\/\/)?(?:www.)?youtu(?:be|.be)?(?:\.com)?/, // base youtube URL
        /\/(?!channel)/, // exclude channel links
        /(?:watch\?v=)?(?:embed\/watch\?feature=player_embedded&v=)?/, // watch mode
        /([a-zA-Z0-9_-]*)(.*)/ // video ID and params (ex timestamps)
    ], "g");
}

function combinedRegex(listOfRegex: RegExp[], flag: string): RegExp {
    return new RegExp(
        listOfRegex
            .map((part: RegExp) => part.source)
            .join(""),
        flag);
}
