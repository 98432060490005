import {
    domainBasedIntranetAccessService,
    featureFlagService,
    intranetService
} from "@web/services";
import { VuexRootState } from "@web/store";
import {
    INITIALIZE_INTRANET,
    LOAD_AVAILABLE_INTRANETS,
    LOAD_PERMISSION_FOR_CURRENT_INTRANET,
    RELOAD_CURRENT_INTRANET,
    RESET_INTRANETS,
    TOGGLE_FEATURE,
    UPDATE_DOMAIN_INTRANET_ACCESS,
    UPDATE_INTRANET,
    UPDATE_INTRANET_TEAM_COUNT
} from "@web/store/intranet/actions";
import {
    COLORS,
    CONTACT_UIDS,
    FEATURE_PREVIEW_ACTIVE,
    INITIALIZED,
    INTRANET,
    INTRANET_UID,
    IS_ADMIN,
    IS_FEATURE_ENABLED,
    SUBSCRIPTION_DAYS_LEFT,
    SUBSCRIPTION_EXPIRATION_DATE,
    SUBSCRIPTION_STATUS,
    LOGO_URL
} from "@web/store/intranet/getters";
import {
    INITIALIZE,
    RESET_INTRANET_MODULE,
    SET_AVAILABLE_INTRANETS,
    SET_COLORS,
    SET_HAS_USER_NAVIGATED_TO_AN_INTRANET,
    SET_INTRANET,
    SET_LOGO,
    SET_PERMISSIONS,
    SET_TEAM_COUNT
} from "@web/store/intranet/mutations";
import {
    Intranet,
    IntranetRole,
    IntranetUpdateRequest,
    PermissionsForIntranetUid
} from "@backend/intranet/types";
import { Module } from "vuex";
import { DomainBasedIntranetAccessConfigurationError } from "@web/exceptions";
import Vue from "vue";
import {
    dayjsFromDate,
    toDate
} from "@web/lib/time-utils";
import dayjs from "dayjs";
import { IntranetFeatureFlag } from "@backend/common/feature-flags/types";
import { ProductSubscriptionStatus } from "@backend/product-subscription/types";

export const INTRANET_MODULE_NAME = "intranet/";
export const LAST_VISITED_INTRANET = "last-vistited-intranet";

export interface IntranetModuleState {
    initialized: boolean; // is currently chosen intranet loaded?
    intranet: Intranet | null; // current intranet object
    availableIntranets: Intranet[]; // all available intranets (loaded for login screen or other overviews)
    permissions: PermissionsForIntranetUid[]; // permission/intranetUid pairs
    loadingIntranets: boolean;
    hasUserNavigatedToAnIntranet: boolean; // if false AvailableIntranets.vue enters the previous used system automatically.

}

const getDefaultState = () => ({
    initialized: false,
    intranet: null,
    permissions: [],
    availableIntranets: [],
    loadingIntranets: true,
    hasUserNavigatedToAnIntranet: false,

});

export const INTRANET_MODULE = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        [INTRANET]: state => state.intranet,
        [INTRANET_UID]: state => state.intranet ? state.intranet.uid : null,
        [INITIALIZED]: state => state.initialized,
        [IS_ADMIN]: ({ permissions, intranet }) => {
            if (intranet) {
                const permission = permissions.find(p => p.intranetUid === intranet.uid);
                return permission && permission.roles ? !!permission.roles.find(r => r === IntranetRole.admin) : false;
            }
        },
        [COLORS]: ({ intranet }) => {
            if (intranet) {
                return intranet.colors;
            }
        },
        [LOGO_URL]: ({ intranet }) => {
            if (intranet) {
                return intranet.logoUrl;
            }
        },
        [FEATURE_PREVIEW_ACTIVE]: ({ intranet }) => {
            return false;
            // TODO HALO-6474: feature preview will be removed, but is temporarily disabled through workaround for fast release
            // if (intranet) {
            //     const flag = intranet.flags["hide-feature-previews"];
            //     return !flag || !flag.enabled;
            // }
        },
        [IS_FEATURE_ENABLED]: ({ intranet }) => (featureFlag: IntranetFeatureFlag) => {
            if (!intranet || !intranet.flags[IntranetFeatureFlag.companyHappiness]) {
                return true;
            }
            return intranet.flags[IntranetFeatureFlag.companyHappiness]!.enabled;
        },
        [SUBSCRIPTION_EXPIRATION_DATE]: ({ intranet }) => {
            if (intranet && intranet.subscription) {
                return toDate(intranet.subscription.expirationDate);
            }
            return undefined;
        },
        [SUBSCRIPTION_DAYS_LEFT]: ({ intranet }, getters) => {
            if (intranet && intranet.subscription) {
                if (getters[SUBSCRIPTION_EXPIRATION_DATE]) {
                    const dayJSExpirationDate = dayjsFromDate(getters[SUBSCRIPTION_EXPIRATION_DATE]);
                    const now = dayjs();
                    return dayJSExpirationDate.diff(now, "days");
                }
            }
            return undefined;
        },
        [SUBSCRIPTION_STATUS]: ({ intranet }) => {
            if (intranet && intranet.subscription) {
                return intranet.subscription.status;
            }
            return ProductSubscriptionStatus.active;
        },
        [CONTACT_UIDS]: ({ intranet }) => {
            if (intranet && intranet.contactUids) {
                return intranet.contactUids;
            }
            return [];
        },
    },
    mutations: {
        [SET_HAS_USER_NAVIGATED_TO_AN_INTRANET]: (state) => {
            state.hasUserNavigatedToAnIntranet = true;
        },
        [INITIALIZE](state, intranet) {
            state.initialized = true;
            state.intranet = intranet;
        },
        [SET_PERMISSIONS](state, roles) {
            state.permissions = roles;
        },
        [SET_AVAILABLE_INTRANETS](state, intranets: Intranet[]) {
            // only update intranets if others are available
            if (!state.availableIntranets.length || intranets.length !== state.availableIntranets.length || !intranets.every(i => state.availableIntranets.some(i2 => i2.uid === i.uid))) {
                state.availableIntranets = intranets;
            }
            state.loadingIntranets = false;
        },
        [SET_LOGO](state, { logoPath, logoUrl }) {
            if (state.intranet) {
                state.intranet.logo = logoPath;
                state.intranet.logoUrl = logoUrl;
            }
        },
        [SET_COLORS](state, colors) {
            if (state.intranet) {
                state.intranet.colors = { ...colors };
            }
        },
        [RESET_INTRANET_MODULE](state) {
            Object.assign(state, getDefaultState());
        },
        [SET_INTRANET](state, intranet: Intranet) {
            state.intranet = { ...intranet };
        },
        [SET_TEAM_COUNT](state, teamCount: number): void {
            if (state.intranet) {
                Vue.set(state.intranet, "teamCount", teamCount);
            }
        },
    },
    actions: {
        async [INITIALIZE_INTRANET]({ commit, dispatch, state }, intranetUid: string) {
            // don't fetch intranet if its already in state
            const intranetFromState = state.availableIntranets.find(i => i.uid === intranetUid);
            const intranet =
                intranetFromState === undefined ? await intranetService.getIntranet(intranetUid, true) : intranetFromState;
            if (intranet) {
                await dispatch(LOAD_PERMISSION_FOR_CURRENT_INTRANET, intranetUid);
                commit(INITIALIZE, intranet);
                return;
            }
            console.error("Invalid intranet id");
        },
        async [RELOAD_CURRENT_INTRANET]({ commit, state }) {
            const intranetId: string | undefined = state.intranet?.uid;
            if (intranetId === undefined) {
                return;
            }
            const intranet = await intranetService.getIntranet(intranetId, true);
            if (intranet) {
                commit(INITIALIZE, intranet);
            }
        },
        async [LOAD_AVAILABLE_INTRANETS]({ commit, rootState }) {
            const { auth } = rootState;
            if (auth.currentUser) {
                const permissions = await intranetService.getAvailableIntranetsForCurrentUser();
                commit(SET_AVAILABLE_INTRANETS, permissions.map(p => p.intranet).sort((a, b) => a.displayName.localeCompare(b.displayName)));
                commit(
                    SET_PERMISSIONS,
                    permissions.map(p => ({ intranetUid: p.intranet.uid, roles: p.roles })),
                );
            }
        },
        async [LOAD_PERMISSION_FOR_CURRENT_INTRANET]({ state, commit, rootState }, intranetUid) {
            const permission = state.permissions.find(p => p.intranetUid === intranetUid);
            const { auth } = rootState;
            if (!auth.currentUser) {
                return;
            }
            if (permission) {
                // don't fetch permission if its already in state
                return;
            }
            const fetchedPermission = await intranetService.getPermissionsForIntranetForUser(
                intranetUid,
                auth.currentUser.uid,
            );
            if (fetchedPermission === null) {
                return;
            }
            commit(SET_PERMISSIONS, [fetchedPermission]);
        },
        async [UPDATE_INTRANET]({ state, commit }, intranetUpdateRequest: IntranetUpdateRequest) {
            if (state.intranet === null) {
                return;
            }
            const updatedIntranet = await intranetService.updateIntranet(state.intranet.uid, intranetUpdateRequest);
            commit(SET_INTRANET, updatedIntranet);
            return updatedIntranet;
        },
        async [UPDATE_DOMAIN_INTRANET_ACCESS]({ state, dispatch }, domains: string[]) {
            if (state.intranet === null) {
                return;
            }
            await domainBasedIntranetAccessService.updateDomains(state.intranet.uid, domains).catch(e => {
                throw new DomainBasedIntranetAccessConfigurationError();
            });
            return dispatch(RELOAD_CURRENT_INTRANET);
        },
        [RESET_INTRANETS]({ commit }) {
            commit(RESET_INTRANET_MODULE);
        },
        [UPDATE_INTRANET_TEAM_COUNT]: {
            root: true,
            handler({ commit }, teamCount: number): void {
                commit(SET_TEAM_COUNT, teamCount);
            },
        },
        async [TOGGLE_FEATURE]({ state, getters, dispatch }, featureFlag: IntranetFeatureFlag) {
            if (state.intranet === null) {
                return;
            }
            const newEnabledState = !getters[IS_FEATURE_ENABLED](featureFlag);
            await featureFlagService.setFeatureFlag(state.intranet.uid, featureFlag, newEnabledState);
            return dispatch(RELOAD_CURRENT_INTRANET);
        },
    },
} as Module<IntranetModuleState, VuexRootState>;
